/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../scss/app.scss';
import '../images/favicon.png';

$(document).ready(function() {
  $('.table').bootstrapTable({
    formatNoMatches: function() {
      return 'NO TASKS DONE DURING THIS TIME PERIOD';
    }
  });
});
